import React, { useState , useEffect} from 'react';
import { Container, Row , Col, Label, Input, Button} from 'reactstrap';
import { useParams, Link} from "react-router-dom";
import { get, endpointPublic, getWithAuth, endpointGlv, endpointAdmin, postwithAuth, deleteWithAuth} from '../../components/HttpUtils';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { formatDate, getFileName, styles } from '../../components/Helper';
import { toast } from 'react-toastify';
import {RiCloseCircleLine} from 'react-icons/ri';
import { BiAddToQueue } from "react-icons/bi";
import ScaleLoader from "react-spinners/ScaleLoader";
import ChangingClassModal from './ChangingClassModal';
import { messages } from '../../components/message';
import './style.css';

toast.configure();
const StudentInClassPresenter = () => {
    const { classId } = useParams();
    const [aClass, setClass] = useState({});
    const [studentList, setStudentList] = useState([]);
    const [showNonResult, setShowNonResult] = useState(false);
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [queryResultList, setQueryResultList] = useState([]);
    const [filterDate, setFilterDate] = useState('')

    useEffect(() => {
        fetchClassById();
        fetchStudents();
    }, []);

    const fetchClassById = () => {
        get(endpointPublic + "/classes/" + classId).then((response) => {
            if(response.status === 200){
                setClass(response.data);
            }
        }).catch((error) => console.log("Fetching student in class error: " + error))
    }

    const fetchStudents = () => {
        get(endpointPublic + "/student-in-class/" + classId).then((response) => {
            if(response.status === 200){
                setStudentList(response.data);
            }
        }).catch((error) => console.log("Fetching student in class error: " + error))
    }

    const addToClass = (studentId, status) => {
        if(!isStatusAllowedToProceed(status)){
            alert(`Không thể thêm tình trạng ${status} vào danh sách!`);
            return;
        }

        if(checkIfUserIdInClass(studentId))
            return;

        const data = {
            "userId": studentId,
            "classId": classId
        }
        postwithAuth(endpointAdmin + "/learns", data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                console.log("Add user to a class successfully!");

                toast.success(messages.insertSuccess, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 2000,
                });

                setTimeout(function () {
                    fetchStudents();
                }, 2000);
            }
        }).catch(error => {
            console.log("error inserting new user: " + error);
        })
    }

    const checkIfUserIdInClass = (userId) => {
        for(let student of studentList){
            if(student.userId === userId){
                alert("Học Viên này đã có trong lớp!");
                return true;
            }
        }
        return false;
    }

    const deleteUserInClass = (userId) =>{
        if (window.confirm(messages.deleteConfirm)) {
            deleteWithAuth(endpointAdmin + "/learns/" + userId + "/" + classId).then((response) => {
                if (response.status === 200) {
                    toast.success(messages.deleteSuccess, {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 1000,
                    });
                    fetchStudents();
                }
            }).catch(error => {
                if (error.response) {
                    toast.error(messages.deleteFailed + " Không được xóa user có thông tin lớp học và điểm", {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                    });
                }
                console.log("Delete user error: " + error);
            })
        }
    }

    const onSearching = (event) => {
        let query = event.target.value.toLowerCase().trim();
        setQuery(query);
    };

    const queryUser = async (queryVal, dateVal) => {
        if(queryVal.trim() === '' && dateVal === '')
          return;
    
        setLoading(true)
        await getWithAuth(`${endpointGlv}/users-short-ver?info=${queryVal.trim()}&date=${dateVal}&status=`).then((response) => {
          if(response.status === 200){
            if(response.data.length === 0){
              setShowNonResult(true)
            }
            else setShowNonResult(false)
            setQueryResultList(response.data);
          }
        }).catch((error) => console.log("Query users error: " + error))
    }

    const isStatusAllowedToProceed = (status) => {
        return status === "ĐANG HỌC";
    }

    return (
        <div>
            <Container>
            <Row>
                <h3 className="alert alert-success" align="center">DANH SÁCH THIẾU NHI THEO LỚP</h3>
            </Row>

            <Row>
                <Col sm="12" md="8">
                <strong>
                    <Label for="search">Tra cứu</Label>
                </strong>
                <br></br>
                <input
                    type="search"
                    name="search"
                    style={{ width: "100%", height: "2.5rem" }}
                    placeholder="Nhập mã thiếu nhi, tên thánh, họ tên thiếu nhi, SĐT phụ huynh, họ tên phụ huynh,..."
                    onChange={onSearching}
                    onKeyDown={(e) => {
                    if (e.code === "Enter") {
                        e.preventDefault();
                        queryUser(e.target.value, filterDate).then(() => setLoading(false))
                    }
                }}
                />
                {showNonResult && <p style={{color:"blue"}}>Thông tin không tồn tại</p>}
                </Col>

                <Col sm="12" md="4">
                <div style={{ display: "inline-block" }}>
                    <strong>Chọn ngày sinh/ rửa tội: </strong>
                    <Input
                    style={{ width: "10rem", marginLeft: "1rem" }}
                    type="date"
                    onChange={(e) => setFilterDate(e.target.value)}
                    />
                </div>

                <Button color="dark" onClick={() => queryUser(query, filterDate).then(() => setLoading(false))}>Tìm</Button>
                </Col>
            </Row>

            <Row>
                <div style={styles}>
                <ScaleLoader
                    color="brown"
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>
            </Row>

            {queryResultList.length > 0 && 
            <Row style={{marginTop: "2rem"}} sm="12">
                <table className='table table-bordered table-hover' id='query-results'>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Mã thiếu nhi</th>
                            <th>Tên Thánh</th>
                            <th>Họ, tên đệm</th>
                            <th>Tên</th>
                            <th>Ngày sinh</th>
                            <th>Ngày rửa tội</th>
                            <th>Tình trạng</th>
                            <th></th>
                        </tr>
                    </thead>
                            
                    <tbody>
                        {queryResultList.map((student, index) => (
                            <tr key={student.userId} id={student.userId} >
                                <td>{index+1}</td>
                                <td>
                                <Link to={{ pathname: `/user/details/` + student.userId }} target="_blank">&nbsp;{student.userId}</Link>
                                </td>
                                <td>{student.hollyName}</td>
                                <td>{student.lastName}</td>
                                <td>{student.firstName}</td>
                                <td>{formatDate(student.birthday)}</td>
                                <td>{formatDate(student.christenDate)}</td>
                                <td>{student.status}</td>
                                <td><BiAddToQueue style={{cursor: 'pointer'}} onClick={() => addToClass(student.userId, student.status)}/></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Row>}

            <hr/>
            <Row style={{marginTop: '2rem'}}>
                <Col>
                    <div style={{float:"left"}}>
                        <label>Mã Lớp: <b>{classId}</b></label>
                        <br/>
                        <label>Tên Lớp: <b>{aClass.name}</b></label>
                    </div>
                </Col>
                <Col>
                    <div>
                        <label>GLV: <b>{aClass.mainTeacher}</b></label>
                        <br/>
                        <label>Trợ giảng: <b>{aClass.subTeacher}</b></label>
                    </div>
                </Col>

                <Col>
                    <div style={{float: "right"}}>
                        <label>Năm học: <b>{aClass.academicYearId}</b></label>
                        <br/>
                        <label>Số lượng: <b>{studentList.length}</b></label>
                    </div>
                    
                </Col>
            </Row>

            <Row style={{marginTop:"1rem"}}>
                <table className='table table-bordered table-hover' id='students-in-class'>
                    <caption style={{captionSide: "top", textAlign: "center"}}>
                        <h4>{`DANH SÁCH THIẾU NHI LỚP ${aClass.name} (NĂM HỌC ${aClass.academicYearId})`}</h4>
                    </caption>
                    <caption style={{captionSide: "top"}} hidden={true}>
                        <h3>GIÁO XỨ THÁNH GIUSE</h3>
                        <br/>
                        <h4>GIỚI THIẾU NHI</h4>
                        <br/>
                        <p>GLV: <b>{aClass.mainTeacher}</b></p>
                        <br/>
                        <p>Trợ giảng: <b>{aClass.subTeacher}</b></p>
                        <br/>
                        <u>Sĩ số: {studentList.length}</u>
                    </caption>
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Mã thiếu nhi</th>
                            <th>Tên Thánh, Họ tên</th>
                            <th>Ngày sinh</th>
                            <th>Rửa tội</th>
                            <th>Tên Cha</th>
                            <th>Tên Mẹ</th>
                            <th>SĐT cha</th>
                            <th>SĐT mẹ</th>
                            <th>Giáo họ</th>
                            <th>Địa chỉ</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    
                    <tbody>
                        {studentList.map((student, index) => (
                            <tr key={student.classId} id={student.userId}>
                                <td>{index+1}</td>
                                <td>
                                    <Link to={{ pathname: `/user/details/` + student.userId }} target="_blank">&nbsp;{student.userId}</Link>
                                </td>
                                <td>{student.fullName}</td>
                                <td>{formatDate(student.birthday)}</td>
                                <td>{formatDate(student.christenDate)}</td>
                                <td>{student.dadName}</td>
                                <td>{student.momName}</td>
                                <td>&nbsp;{student.dadPhone}</td>
                                <td>&nbsp;{student.momPhone}</td>
                                <td>{student.region}</td>
                                <td>{student.address}</td>
                                <td>
                                    <ChangingClassModal 
                                        userId={student.userId}
                                        fullName={`${student.fullName}`}
                                        oldClassId={classId}
                                    />
                                </td>
                                <td>
                                    <RiCloseCircleLine color="red" style={{cursor: 'pointer'}} onClick={() => deleteUserInClass(student.userId)} />    
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <br/>
                <br/>
                <br/>
                <br/>
                <hr/>
            </Row>

            <div style={{float: "right", marginBottom:"2rem"}}>
                {studentList.length>0 ? <ReactHTMLTableToExcel
                    id="students-of-class"
                    className="download-table-xls-button"
                    table="students-in-class"
                    filename={getFileName()}
                    sheet={classId}
                    buttonText="Xuất File Excel"/> : undefined}
            </div>
            
        </Container>
        </div>
    );
}

export default StudentInClassPresenter;