import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import './navbar.css';

class Navbar extends Component {
    state = { loginName: "" }

    renderWhenNoLogin() {
        return (
            <DropdownMenu>
                <Link className="link" to="/account/signin"><DropdownItem>Đăng nhập</DropdownItem></Link>
                <Link className="link" to="/account/reset-password"><DropdownItem>Quên mật khẩu</DropdownItem></Link>
            </DropdownMenu>
        );
    }

    renderWhenLoggedIn() {
        if (localStorage.getItem("role").includes( "ROLE_ADMIN")) {
            return (
                <DropdownMenu>
                    <Link className="link" to="/account/logout"><DropdownItem>Đăng xuất</DropdownItem></Link>
                    <Link className="link" to="/attendances"><DropdownItem>Quản lý điểm danh</DropdownItem></Link>
                    <Link className="link" to="/cards"><DropdownItem>Quản lý thẻ</DropdownItem></Link>
                    <Link className="link" to="/admin/leaves"><DropdownItem>Quản lý vắng phép</DropdownItem></Link>
                    <Link className="link" to="/admin/users"><DropdownItem>Quản lý người dùng</DropdownItem></Link>
                    <Link className="link" to="/admin/classes"><DropdownItem>Quản lý lớp</DropdownItem></Link>
                    <Link className="link" to="/admin/academic-years"><DropdownItem>Quản lý năm học</DropdownItem></Link>
                    <Link className="link" to="/admin/grades"><DropdownItem>Quản lý khối</DropdownItem></Link>
                    <Link className="link" to={{ pathname: `/account/change-password/` + localStorage.getItem("userId") }}><DropdownItem>Đổi mật khẩu</DropdownItem></Link>
                </DropdownMenu>
            );
        }
        else if (localStorage.getItem("role").includes( "ROLE_GLV")) {
            return (
                <DropdownMenu>
                    <Link className="link" to="/account/logout"><DropdownItem>Đăng xuất</DropdownItem></Link>
                    <Link className="link" to="/attendances"><DropdownItem>Quản lý điểm danh</DropdownItem></Link>
                    <Link className="link" to="/cards"><DropdownItem>Quản lý thẻ</DropdownItem></Link>
                    <Link className="link" to={{ pathname: `/account/change-password/` + localStorage.getItem("userId") }}><DropdownItem>Đổi mật khẩu</DropdownItem></Link>
                </DropdownMenu>
            );
        }
        return (
            <DropdownMenu>
                <Link className="link" to="/account/logout"><DropdownItem>Đăng xuất</DropdownItem></Link>
                <Link className="link" to={{ pathname: `/account/change-password/` + localStorage.getItem("userId") }}><DropdownItem>Đổi mật khẩu</DropdownItem></Link>
            </DropdownMenu>
        );
    }

    render() {
        return (
            <div>
                <nav id='navbar'>
                    <ul>
                        <Link to="/"><li>Trang chủ</li></Link>
                        <Link to="/about"><li>Thông tin</li></Link>

                        <UncontrolledDropdown color="#10cebe;"
                            style={{ display: 'inline-block' }}>
                            <DropdownToggle caret>
                                Chức năng
                            </DropdownToggle>
                            {localStorage.getItem("userId") === null ? this.renderWhenNoLogin() : this.renderWhenLoggedIn()}
                        </UncontrolledDropdown>
                    </ul>

                    <div className="nav-details">
                        {localStorage.getItem("token") ? <Link to="/account/details"><p className="nav-userId">
                            Xin chào, {localStorage.getItem("userId")}</p></Link> : ''}
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;