import React, { useState, useEffect } from "react";
import { endpointGlv, endpointPublic, get, getWithAuth } from "../HttpUtils";
import { checkUserLogged, formatDate, getFileName } from "../Helper";
import { Row, Col, Input, Label, Button } from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ScaleLoader from "react-spinners/ScaleLoader";
import '../../App.css';

const Home = () => {
  const history = useHistory();
  const [classList, setClassList] = useState([]);
  const [studentInClassList, setStudentInClassList] = useState([]);
  const [activeAcademicYearName, setActiveAcademicYearName] = useState('');
  const [filterClass, setFilterClass] = useState("");
  const [query, setQuery] = useState("");
  const [queryResultList, setQueryResultList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterDate, setFilterDate] = useState('');
  const [showNonResult, setShowNonResult] = useState(false);
  const [aclass, setClass] = useState({});

  const styles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  useEffect(() => {
    let logged = checkUserLogged();
    if (!logged) {
      history.push("/account/signin");
      return;
    }
    getCurrentAcademicYear();
    getCurrentClasses();
  }, []);

  const getCurrentAcademicYear = () => {
    get(endpointPublic + "/active-academic-year").then((response) => {
      if (response.status === 200) {
        setActiveAcademicYearName(response.data.name);
      }
    }).catch((error) => console.log("Fetching current classes error: " + error))
  }

  const getCurrentClasses = () => {
    get(endpointPublic + "/classes/current").then((response) => {
      if (response.status === 200) {
        setClassList(response.data);
      }
    }).catch((error) => console.log("Fetching current classes error: " + error))
  }

  const fetchStudents = async (classId) => {
    await get(endpointPublic + "/student-in-class/" + classId).then((response) => {
      if (response.status === 200) {
        setStudentInClassList(response.data);
      }
    }).catch((error) => console.log("Fetching student in class error: " + error))
  }

  const handleClassFilterChange = (event) => {
    setStudentInClassList([]);
    let value = event.target.value;
    if (value !== undefined && value !== null && value !== '')
      setLoading(true);
    else
      setLoading(false);
    setFilterClass(event.target.value);
    fetchClassById(event.target.value);
    fetchStudents(event.target.value).then(() => setLoading(false));
  }

  const onSearching = (event) => {
    let query = event.target.value.toLowerCase().trim();
    setQuery(query);
  };

  const queryUser = (queryVal, dateVal) => {
    if (queryVal.trim() === '' && dateVal === '')
      return;

    let queryStr = '';
    let params = {};
    if (queryVal.trim() !== '') {
      params['info'] = queryVal.trim();
    }
    if (dateVal !== '') {
      params['date'] = dateVal;
    }

    queryStr = Object
      .entries(params) // return an array of enumerable string-keyed property key-value pairs.
      .map(arrItem => arrItem.join('=')) // param1=val1, param2=val2
      .join('&'); // param1=val1&param2=val2

    getWithAuth(`${endpointGlv}/query-user?${queryStr}`).then((response) => {
      if (response.status === 200) {
        if (response.data.length === 0) {
          setShowNonResult(true)
        }
        else setShowNonResult(false)
        setQueryResultList(response.data);
      }
    }).catch((error) => console.log("Query users error: " + error))
  }

  const fetchClassById = (classId) => {
    get(endpointPublic + "/classes/" + classId).then((response) => {
      if (response.status === 200) {
        setClass(response.data);
      }
    }).catch((error) => console.log("Fetching student in class error: " + error))
  }

  return (
    <div className="home-page" style={{ marginBottom: "10rem" }}>
      <h1
        className="alert alert-success"
        align="center"
        style={{ marginTop: "2rem" }}
      >
        {activeAcademicYearName}
      </h1>

      <Row>
        <h3>DANH MỤC TÌM KIẾM</h3>
      </Row>

      <Row>
        <Col sm="12" md="8">
          <strong>
            <Label for="search">Tra cứu</Label>
          </strong>
          <br></br>
          <input
            type="search"
            name="search"
            style={{ width: "100%", height: "2.5rem" }}
            placeholder="Nhập mã thiếu nhi, tên thánh, họ tên thiếu nhi, SĐT phụ huynh, họ tên phụ huynh,..."
            onChange={onSearching}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                queryUser(e.target.value, filterDate)
              }
            }}
          />
          {showNonResult && <p style={{ color: "blue" }}>Thông tin không tồn tại</p>}
        </Col>

        <Col sm="12" md="4">
          <div style={{ display: "inline-block" }}>
            <strong>Chọn ngày sinh/ rửa tội: </strong>
            <Input
              style={{ width: "10rem", marginLeft: "1rem" }}
              type="date"
              onChange={(e) => setFilterDate(e.target.value)}
            />
          </div>

          <Button color="dark" onClick={() => queryUser(query, filterDate)}>Tìm</Button>
        </Col>
      </Row>

      {(queryResultList.length > 0) && <p>Kết quả tìm được: <b>{queryResultList.length}</b></p>}

      {queryResultList.length > 0 &&
        <Row className='wrapped-table' sm="12">
          <table className='table table-bordered table-hover' id='query-results'>
            <thead>
              <tr>
                <th>STT</th>
                <th>Mã số</th>
                <th>Tên Thánh</th>
                <th>Họ, tên đệm</th>
                <th>Tên</th>
                <th>Ngày sinh</th>
                <th>Rửa tội</th>
                <th style={{ textAlign: 'center' }}>Lớp</th>
                <th>Tên Cha</th>
                <th>Tên Mẹ</th>
                <th>Liên hệ</th>
                <th>Giáo họ</th>
                <th>Tình trạng</th>
              </tr>
            </thead>

            <tbody>
              {queryResultList.map((student, index) => (
                <tr key={index} id={index} >
                  <td>{index + 1}</td>
                  <td>
                    <Link to={{ pathname: `/user/details/` + student.user_id_result }}
                      target="_blank">&nbsp;{student.user_id_result}
                    </Link>
                  </td>
                  <td>{student.holly_name_result}</td>
                  <td>{student.last_name_result}</td>
                  <td>{student.first_name_result}</td>
                  <td>{formatDate(student.birthday_result)}</td>
                  <td>{formatDate(student.christen_date_result)}</td>
                  <td>{student.class_id_result}</td>
                  <td>{student.father_name_result}</td>
                  <td>{student.mother_name_result}</td>
                  <td>
                    <ul>
                      {student.dad_phone && <li>&nbsp;{student.dad_phone}</li>}
                      {student.mom_phone && <li>&nbsp;{student.mom_phone}</li>}
                    </ul>
                  </td>
                  <td>{student.region_result}</td>
                  <td>{student.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>}

      <br />
      {queryResultList.length > 0 &&
        <div style={{ float: 'right' }}>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="query-results"
            filename={getFileName()}
            sheet="data"
            buttonText="Xuất File Excel" />
        </div>
      }
      <br/>
      <hr/>

      <Row>
        <h3>XEM DANH SÁCH LỚP</h3>
      </Row>

      <Row>
        <Col>
          <Label for="class-academic-year"><b>Chọn lớp: </b></Label>
          <Input type="select" name="class-academic-year"
            style={{ width: "20rem" }}
            onChange={e => handleClassFilterChange(e)}>
            <option>---------------Lớp học---------------</option>
            {classList.map((currentClass) => (
              <option key={currentClass.classId}
                value={currentClass.classId}
                onChange={(e) => setFilterClass(e.target.value)} >{currentClass.name}</option>
            ))}
          </Input>
        </Col>

      </Row>

      {loading &&
        <Row>
          <div style={styles}>
            <ScaleLoader
              color="brown"
              loading={loading}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        </Row>
      }

      {filterClass &&
        <Row style={{ marginTop: '2rem' }}>
          <Col>
            <div style={{ float: "left" }}>
              <label>Mã Lớp: <b>{aclass.classId}</b></label>
              <br />
              <label>Tên Lớp: <b>{aclass.name}</b></label>
            </div>
          </Col>
          <Col>
            <div>
              <label>GLV: <b>{aclass.mainTeacher}</b></label>
              <br />
              <label>Trợ giảng: <b>{aclass.subTeacher}</b></label>
            </div>
          </Col>

          <Col>
            <div style={{ float: "right" }}>
              <label>Năm học: <b>{aclass.academicYearId}</b></label>
              <br />
              <label>Số lượng: <b>{studentInClassList.length}</b></label>
            </div>

          </Col>
        </Row>
      }

      {studentInClassList.length > 0 &&
        <Row className='wrapped-table'>
          <table className='table table-bordered table-hover' id='students-in-class'>
            <caption style={{ captionSide: "top", textAlign: "center" }}>
              <h4>{`DANH SÁCH THIẾU NHI LỚP ${aclass.name} (NĂM HỌC ${aclass.academicYearId})`}</h4>
            </caption>
            <caption style={{ captionSide: "top" }} hidden={true}>
              <h3>GIÁO XỨ THÁNH GIUSE</h3>
              <br />
              <h4>GIỚI THIẾU NHI</h4>
              <br />
              <p>GLV: <b>{aclass.mainTeacher}</b></p>
              <br />
              <p>Trợ giảng: <b>{aclass.subTeacher}</b></p>
              <br />
              <u>Sĩ số: {studentInClassList.length}</u>
            </caption>
            <thead>
              <tr>
                <th>STT</th>
                <th>Mã thiếu nhi</th>
                <th>Tên Thánh, Họ tên</th>
                <th>Tên Cha</th>
                <th>Tên Mẹ</th>
                <th>SĐT cha</th>
                <th>SĐT mẹ</th>
                <th>Giáo họ</th>
                <th>Địa chỉ</th>
              </tr>
            </thead>

            <tbody>
              {studentInClassList.map((student, index) => (
                <tr key={student.userId} id={student.userId}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={{ pathname: `/user/details/` + student.userId }} target="_blank">&nbsp;{student.userId}</Link>
                  </td>
                  <td>{student.fullName}</td>
                  <td>{student.dadName}</td>
                  <td>{student.momName}</td>
                  <td>&nbsp;{student.dadPhone}</td>
                  <td>&nbsp;{student.momPhone}</td>
                  <td>{student.region}</td>
                  <td>{student.address}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <br />
          <br />
        </Row>
      }

      {studentInClassList.length > 0 ? (
        <div style={{float: 'right'}}>
          <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="students-in-class"
            filename={getFileName()}
            sheet={filterClass}
            buttonText="Xuất File Excel"
          />
        </div>
      ) : (
        undefined
      )}

    </div>
  );
};

export default Home;
