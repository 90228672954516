import React, {useState, useEffect} from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Col, Input, Label, Row, Button } from "reactstrap";
import { addDateStrRepresentation, formateDateString, getFileName } from "../../components/Helper";
import { get, endpointPublic, getWithAuth, endpointAdmin, endpointGlv } from "../../components/HttpUtils";
import ScaleLoader from "react-spinners/ScaleLoader";
import { MultiSelect } from "react-multi-select-component";
import { Link } from "react-router-dom";


const AttendanceTimeRangeGrouping = () => {
    const [activeAcademicYearName, setActiveAcademicYearName] = useState('');
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [selected, setSelected] = useState([]);
    const [attendanceList, setAttendanceList] = useState([]);

    const styles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };
    
    useEffect(() => {
        getCurrentAcademicYear();
    }, []);

    const getCurrentAcademicYear = () => {
        get(endpointPublic + "/active-academic-year").then((response) => {
            if (response.status === 200) {
                setActiveAcademicYearName(response.data.name);
            }
        }).catch((error) => console.log("Fetching academic year error: " + error))
    }

    const getAttendance = async (selectedTypes, startDate, endDate) => {
        if(selectedTypes !== "" && selectedTypes !== undefined){
            setLoading(true)
            let scanningTypes = []
            for(let index in selectedTypes){
                let gradeValue = selectedTypes[index].value
                scanningTypes.push(gradeValue)
            }
            await getWithAuth(`${endpointGlv}/attendances/filter-by-date-range-types?startDate=${startDate}&endDate=${endDate}&types=${scanningTypes}`)
            .then((response) => {
                if (response.status === 200) {
                  let result = [...response.data]
                  let attendancesSortedClass = result.sort((a, b) => (a.classId > b.classId) ? 1 : -1);
                    setAttendanceList(attendancesSortedClass);
                }
                
            }).catch((error) => console.log("Fetching attendance error: " + error))
        }
    }

    const getTableTitle = () => {
        if(startDate === '' && endDate === ''){
            return <h4>DANH SÁCH ĐI LỄ TỪ ĐẦU NĂM ({activeAcademicYearName})</h4>
        }
        else if(startDate !== '' && endDate !== ''){
            return <h4>DANH SÁCH ĐI LỄ TỪ NGÀY {formateDateString(startDate)} ĐẾN NGÀY {formateDateString(endDate)} - ({activeAcademicYearName})</h4>
        }
        else if(startDate !== ''){
            return <h4>DANH SÁCH ĐI LỄ TỪ NGÀY {formateDateString(startDate)} - ({activeAcademicYearName})</h4>
        }
    }

    const scanningTypes = [
      {"label": "Lễ thứ 3, 5", "value": "T3T5"},
      {"label": "Lễ thứ 2, 4, 6", "value": "KHAC"},
      {"label": "Đi lễ CN sớm", "value": "LESOM"},
      {"label": "Học giáo lý", "value": "GIAOLY"},
    ]

    return(
        <div>
            <Row>
                <Col>
                <div>
                    <Label for="select-scanning-types"><b>Loại điểm danh:</b></Label>
                    <MultiSelect
                        options={scanningTypes}
                        value={selected}
                        onChange={setSelected}
                    />
                    </div>
                </Col>

                <Col>
                    <Label for="start-date"><b>Ngày bắt đầu:</b></Label>
                    <Input type="date"name="start-date" lang="vn-CA"
                        value={startDate} max={new Date().toISOString().split('T')[0]} 
                        placeholder="Nhập ngày bắt đầu"
                        onChange={e => setStartDate(e.target.value)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Col>

                <Col>
                    <Label for="end-date"><b>Ngày kết thúc:</b></Label>
                    <Input type="date"name="end-date"
                        value={endDate} max={new Date().toISOString().split('T')[0]} 
                        placeholder="Nhập ngày kết thúc"
                        onChange={e => setEndDate(e.target.value)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Col>

                <Col>
                    <br/>
                    <Button color="dark" onClick={() => getAttendance(selected, startDate, endDate).then(()=>setLoading(false))}>
                        Lọc
                    </Button>
                </Col>
            </Row>

            <Row>
                <div style={styles}>
                <ScaleLoader
                    color="brown"
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>
            </Row>

            {attendanceList.length > 0 && 
                <Row>
                    <table style={{marginTop: '1rem'}} className="table table-hover table-bordered" id="attendances">
                        <caption style={{captionSide: "top", textAlign: "center"}}>
                            {getTableTitle()}
                        </caption>
                        <caption style={{captionSide: "top", textAlign: "right"}} hidden={true}>GIÁO XỨ THÁNH GIUSE</caption>
                        
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Mã thiếu nhi</th>
                                <th>Thông tin</th>
                                <th>Lớp</th>
                                <th>Ngày dự lễ</th>
                                <th>Tổng (ngày)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {attendanceList.map((attendance, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>
                                        <Link to={{ pathname: `/attendances/detail/` + attendance.userId }} target="_blank">&nbsp;{attendance.userId}</Link>
                                    </td>
                                    <td>&nbsp;{attendance.info}</td>
                                    <td>{attendance.classId.slice(2)}</td>
                                    <td>
                                        <ul>
                                            {attendance.attendedDates.map((date) => (<li>{addDateStrRepresentation(date)}</li>))}
                                        </ul>
                                    </td>
                                    <td>{attendance.attendedDates.length}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            }

            {attendanceList.length>0 ? <ReactHTMLTableToExcel
                id="attendancesExcel"
                className="download-table-xls-button"
                table="attendances"
                filename={getFileName()}
                sheet='LỄ NGÀY THƯỜNG'
                buttonText="Xuất File Excel"/> : undefined}
            <hr/>
            <br/>
        </div>
    );
}

export default AttendanceTimeRangeGrouping;