import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { get, endpointUser, getWithAuth, endpointPublic, endpointAdmin, putWithAuth } from '../../components/HttpUtils';
import { toast } from 'react-toastify';
import { FaPen } from 'react-icons/fa';
import 'react-toastify/dist/ReactToastify.css';
import { messages } from '../../components/message';

toast.configure();
const LeaveEditModal = (props) => {
    const {
        className,
        title,
        color,
        leaveId,
        onMergeLeave
    } = props;

    const [info, setInfo] = useState('');
    const [userId, setUserId] = useState('');

    const [modal, setModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [disable, setDisable] = useState(true);
    const [academicYears, setAcademicYears] = useState([]);

    const [filterLeaveType, setFilterLeaveType] = useState('N35');
    const [reason, setReason] = useState('');
    const [compensateDate, setCompensateDate] = useState('');
    const [absentDate, setAbsentDate] = useState('');
    const [notes, setNotes] = useState('');
    const [filterAcademic, setFilterAcademic] = useState('');


    const toggle = () => {
        setModal(!modal);
        if(!modal){
            getLeaveById();
        }
    }

    const getLeaveById = () => {
        if(leaveId === "")
            return;

        getWithAuth(endpointAdmin + "/leaves/" + leaveId).then((response) => {
            getUser(response.data.userId);
            setFilterLeaveType(response.data.leaveTypeId)
            setReason(response.data.reason);
            setCompensateDate(response.data.compensateDate)
            setAbsentDate(response.data.absentDate)
            setNotes(response.data.notes)
            setFilterAcademic(response.data.academicYearId)
        }).catch(error => {
            console.log("error getting user details" + error);
            let errors = {};
            errors['user'] = messages.userNotFound;
            setErrors(errors);
            setInfo('');
            setDisable(true);
        })
    }
    
    const getUser = (userId) => {
        setUserId(userId);
        setInfo('');
        setDisable(true);

        getWithAuth(endpointUser + "/" + userId).then((response) => {
            let hollyName = response.data.hollyName;
            let lastName = response.data.lastName;
            let firstName = response.data.firstName;
            if (response.status === 200 && userId.trim().length === 5) {
                if(hollyName !== undefined && hollyName !== '' && lastName !== undefined && lastName !== '' && firstName !== undefined && firstName !== ''){
                    setErrors({})
                    setInfo(response.data.hollyName + ' ' + response.data.lastName + ' ' + response.data.firstName);
                    setDisable(false);
                }
            }
        }).catch(error => {
            console.log("error getting user details" + error);
            let errors = {};
            errors['user'] = messages.userNotFound;
            setErrors(errors);
            setInfo('');
            setDisable(true);
        })
    }

    const validateForm = () => {
        let errors = {}, formIsValid = true;
        if(userId === undefined || userId === null || userId === ""){
            errors['user'] = messages.emptyUser;
            formIsValid = false;
        }
        if (reason === undefined || reason === null || reason === "") {
            errors['reason'] = "Vui lòng điền lý do";
            formIsValid = false;
        }
        if (filterLeaveType === undefined || filterLeaveType === null || filterLeaveType === "") {
            errors['filterLeaveType'] = "Vui lòng chọn loại phép";
            formIsValid = false;
        }
        if (filterAcademic === undefined || filterAcademic === null || filterAcademic === "") {
            errors['filterAcademic'] = "Vui lòng chọn năm học";
            formIsValid = false;
        }
        if(filterLeaveType === 'NGL' && (absentDate === '' || absentDate === undefined || absentDate === null)){
            errors['absentDate'] = "Vui lòng chọn ngày nghỉ";
            formIsValid = false;
        }
        setErrors(errors);

        return formIsValid;
    }

    const addLeave = () => {
        if(!validateForm())
            return;

        const data = {
            "reason": reason,
            "compensateDate": compensateDate,
            "absentDate": absentDate,
            "notes": notes,
            "leaveTypeId": filterLeaveType,
            "academicYearId": filterAcademic,
            "userId": userId
        }

        let newLeave = undefined;

        putWithAuth(endpointAdmin + "/leaves/" + leaveId, data).then((response) => {
            if (response.status === 200 || response.status === 201) {
                console.log("Update leave successfully!");

                toast.success(messages.updateSuccess, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });

                newLeave = response.data;
                console.log('Updated leave: ', response.data);
            }
        }).catch(error => {
            console.log("error inserting new user: " + error);
        });
        
        setTimeout(function () {
            onMergeLeave(newLeave, true);
            toggle();
        }, 2000);
    }

    return (
        <div>
            <FaPen color={color} style={{cursor: 'pointer'}} onClick={toggle}/>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <Form >
                        <FormGroup>
                            <Label for="userId">Mã thiếu nhi</Label>
                            <Input style={{ width: "20rem" }} 
                                    type="text" name="userId" 
                                    value={userId} maxLength="50" 
                                    id="userId" 
                                    placeholder="Nhập mã thiếu nhi" 
                                    readOnly={leaveId !== ""}
                                    onChange={(e)=>getUser(e.target.value.trim())} />
                            <span style={{ color: "red" }}>{errors["user"]}</span>
                            <span style={{ color: "green" }}>{info}</span>
                        </FormGroup>
                        <FormGroup>
                            <Label for="classId">Loại phép</Label>
                            <Input style={{ width: "20rem" }} 
                                type="select"
                                name="leave-type"
                                id="leave-type"
                                onChange={(e) => setFilterLeaveType(e.target.value)}
                            >
                            <option key={'N35'} value={'N35'} selected={filterLeaveType === 'N35'}>Nghỉ lễ Thứ 3, Thứ 5</option>
                            <option key={'NGL'} value={'NGL'} selected={filterLeaveType === 'NGL'}>Nghỉ học giáo lý</option>
                            <span style={{ color: "red" }}>{errors["filterLeaveType"]}</span>
                        </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="reason">Lý do</Label>
                            <Input 
                                type="text" name="reason" 
                                value={reason} maxLength="50" 
                                id="reason" 
                                placeholder="Nhập lý do" 
                                onChange={(e)=>setReason(e.target.value)} />
                            <span style={{ color: "red" }}>{errors["reason"]}</span>
                        </FormGroup>
                        <FormGroup hidden={filterLeaveType === 'NGL'}>
                            <Label for="compensateDate">Ngày đi bù</Label>
                            <Input style={{ width: "20rem" }} 
                                    type="text" name="compensateDate" 
                                    value={compensateDate} maxLength="50" 
                                    id="compensateDate" 
                                    placeholder="Nhập ngày đi bù" 
                                    onChange={(e)=>setCompensateDate(e.target.value)} />
                        </FormGroup>
                        <FormGroup hidden={filterLeaveType === 'N35'}>
                            <Label for="absentDate">Ngày vắng</Label>
                            <Input style={{ width: "20rem" }} 
                                    type="date" name="absentDate" 
                                    value={absentDate}
                                    id="absentDate"
                                    placeholder="Nhập Ngày vắng" 
                                    onChange={(e)=>setAbsentDate(e.target.value)} />
                            <span style={{ color: "red" }}>{errors["absentDate"]}</span>
                        </FormGroup>
                        
                        <FormGroup>
                            <Label for="notes">Ghi chú phụ</Label>
                            <Input style={{ width: "20rem" }} 
                                    type="text" name="notes" 
                                    value={notes} maxLength="50" 
                                    id="notes" 
                                    placeholder="Nhập ghi chú phụ" 
                                    onChange={(e)=>setNotes(e.target.value)} />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={disable} onClick={()=>addLeave()}>OK</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default LeaveEditModal;