import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Input, CustomInput } from 'reactstrap';
import './SignIn.css'
import { endpointAuth, hostFrontend, post } from '../../HttpUtils';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { messages } from '../../message';
import { checkUserLogged } from '../../Helper';

toast.configure();
class Login extends Component {
    state = {
        userId: "", password: "", roles: [], token: "", tokenType: "", errors: {}, 
        checkedShowPassword: false, fullName: "", passwordType: "password"
    };

    componentDidMount() {
        let logged = checkUserLogged();
        if (logged) {
            if (typeof window !== 'undefined') {
                window.location.href = hostFrontend;
            }
            return;
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.validateForm(e.target.userId.value, e.target.password.value) !== true)
            return;

        this.setState({ userId: e.target.userId.value })
        this.setState({ password: e.target.password.value })

        const credentials = { userId: this.state.userId, password: this.state.password };

        post(endpointAuth + "/signin", credentials).then((response) => {
            if (response.status === 200) {
                this.setState({ userId: response.data.userId })
                this.setState({ userId: response.data.userId })
                this.setState({ roles: response.data.roles })
                this.setState({ token: response.data.token })
                this.setState({ tokenType: response.data.tokenType })
                this.setState({ fullName: response.data.fullName })
                this.saveLogInInfo();

                toast.success(`Xin chào, ${response.data.fullName}`, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                this.props.getLoginName(response.data.userId);
                setTimeout(function () {
                    if(response.data.roles.includes('ROLE_ADMIN') || response.data.roles.includes('ROLE_GLV'))
                        window.location.replace(hostFrontend)
                    else
                        window.location.replace(hostFrontend + "user/details/" + response.data.userId);
                }, 2000);
            }
        }).catch(error => {
            console.log("error sigin: " + error);
            toast.error(messages["loginFailed"], {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 2000,
            });
        })
    }

    saveLogInInfo() {
        localStorage.setItem("userId", this.state.userId);
        localStorage.setItem("token", this.state.token);
        localStorage.setItem("role", this.state.roles.join(", "));
    }

    validateForm(userId, password) {
        let errors = {}, formIsValid = true;

        if (userId.length < 3 || userId.length > 40) {
            errors['userId'] = messages.userIdLength;
            formIsValid = false;
        }
        else if (password.length < 4 || password.length > 40) {
            errors['password'] = messages.passwordLength;
            formIsValid = false;
        }
        this.setState({ errors: errors })

        return formIsValid;
    }

    toggleShowPassword(event) {
        let allowShowing = event.target.checked;
        this.setState({ checkedShowPassword: allowShowing });
        if(allowShowing){
            this.setState({passwordType: "text"});
        }
        else{
            this.setState({passwordType: "password"});
        }
    }

    render() {
        return (
            <div className="login-form">
                <h3>TRANG ĐĂNG NHẬP</h3>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <FormGroup>
                        <Label for="userId">Tên đăng nhập </Label>
                        <Input style={{ width: "20rem" }} type="text" name="userId" required value={this.state.userId}
                            id="userId" placeholder="Nhập tên đăng nhập hoặc email" onChange={e => this.setState({ userId: e.target.value })} />
                        <span style={{ color: "red" }}>{this.state.errors["userId"]}</span>
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Mật khẩu</Label>
                        <Input style={{ width: "20rem" }} type={this.state.passwordType} name="password" required value={this.state.password}
                            id="password" placeholder="Độ dài từ 4 đến 40 kí tự" onChange={e => this.setState({ password: e.target.value })} />
                        <span style={{ color: "red" }}>{this.state.errors["password"]}</span>
                    </FormGroup>
                    <FormGroup>
                        <CustomInput type="checkbox" id="password-toggle" label="Hiển thị mật khẩu"
                            name="password-toggle"
                            checked={this.state.checkedShowPassword} onChange={(e) => this.toggleShowPassword(e)} />
                    </FormGroup>
                    <Button color="info" style={{ marginTop: "1rem" }} type="submit">Đăng nhập</Button>
                    <Link to="/account/reset-password"><p>Quên mật khẩu ?</p></Link>
                </Form>
            </div>
        );
    }
}

export default Login;