import React, {useState, useEffect} from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Col, Input, Label, Row, Button } from "reactstrap";
import { formateDateString, getFileName } from "../../components/Helper";
import { get, endpointPublic, getWithAuth, endpointAdmin } from "../../components/HttpUtils";
import ScaleLoader from "react-spinners/ScaleLoader";
import { MultiSelect } from "react-multi-select-component";


const AttendanceStatisticRank = () => {
    const [activeAcademicYearName, setActiveAcademicYearName] = useState('');
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [grades, setGrades] = useState([])
    const [selected, setSelected] = useState([]);
    const [rankList, setRankList] = useState([]);
    const [gradesTitle, setGradesTitle] = useState('');

    const styles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };
    
    useEffect(() => {
        getCurrentAcademicYear();
        getGrades();
    }, []);

    const getGrades = () => {
        getWithAuth(endpointAdmin + "/grades").then((response) => {
            if (response.status === 200) {
                let options = []
                let rawGradeValues = [...response.data]
                for(let gradeValue in rawGradeValues){
                    let grade = {
                        "label": rawGradeValues[gradeValue].name,
                        "value": rawGradeValues[gradeValue].gradeId
                    }
                    options.push(grade)
                }
                setGrades(options);
            }
        }).catch((error) => console.log("Fetching grades error: " + error))
    }

    const getCurrentAcademicYear = () => {
        get(endpointPublic + "/active-academic-year").then((response) => {
            if (response.status === 200) {
                setActiveAcademicYearName(response.data.name);
            }
        }).catch((error) => console.log("Fetching academic year error: " + error))
    }

    const getRank = async (selectedGrades, startDate, endDate) => {
        if(selectedGrades !== "" && selectedGrades !== undefined){
            setLoading(true)
            let grades = []
            for(let index in selectedGrades){
                let gradeValue = selectedGrades[index].value
                grades.push(gradeValue)
            }
            let gradesStr = grades.join(',')
            setGradesTitle(gradesStr)
            await getWithAuth(`${endpointAdmin}/attendances/sorted-weekday-desc?startDate=${startDate}&groups=${gradesStr}&endDate=${endDate}`).then((response) => {
                if (response.status === 200) {
                    setRankList(response.data);
                }
                
            }).catch((error) => console.log("Fetching rank statistic error: " + error))
        }
    }

    const getTableTitle = () => {
        if(startDate === '' && endDate === ''){
            return <h4>BẢNG XẾP HẠNG THIẾU NHI SIÊNG NĂNG TỪ ĐẦU NĂM - KHỐI {gradesTitle} ({activeAcademicYearName})</h4>
        }
        else if(startDate !== '' && endDate !== ''){
            return <h4>BẢNG XẾP HẠNG THIẾU NHI SIÊNG NĂNG TỪ NGÀY {formateDateString(startDate)} ĐẾN NGÀY {formateDateString(endDate)} - KHỐI {gradesTitle} ({activeAcademicYearName})</h4>
        }
        else if(startDate !== ''){
            return <h4>BẢNG XẾP HẠNG THIẾU NHI SIÊNG NĂNG TỪ NGÀY {formateDateString(startDate)} - KHỐI {gradesTitle} ({activeAcademicYearName})</h4>
        }
    }

    return(
        <div>
            <Row>
                <Col>
                <div>
                    <Label for="select-grades"><b>Chọn khối:</b></Label>
                    <MultiSelect
                        options={grades}
                        value={selected}
                        onChange={setSelected}
                    />
                    </div>
                </Col>

                <Col>
                    <Label for="start-date"><b>Ngày bắt đầu:</b></Label>
                    <Input type="date"name="start-date" lang="vn-CA"
                        value={startDate} max={new Date().toISOString().split('T')[0]} 
                        placeholder="Nhập ngày bắt đầu"
                        onChange={e => setStartDate(e.target.value)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Col>

                <Col>
                    <Label for="end-date"><b>Ngày kết thúc:</b></Label>
                    <Input type="date"name="end-date"
                        value={endDate} max={new Date().toISOString().split('T')[0]} 
                        placeholder="Nhập ngày kết thúc"
                        onChange={e => setEndDate(e.target.value)}
                        onKeyDown={(e) => {
                            e.preventDefault();
                        }}
                    />
                </Col>

                <Col>
                    <br/>
                    <Button color="dark" onClick={() => getRank(selected, startDate, endDate).then(()=>setLoading(false))}>
                        Lọc
                    </Button>
                </Col>
            </Row>

            <Row>
                <div style={styles}>
                <ScaleLoader
                    color="brown"
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>
            </Row>

            {rankList.length > 0 && 
                <Row>
                    <table style={{marginTop: '1rem'}} className="table table-hover table-bordered" id="attendance-ranking">
                        <caption style={{captionSide: "top", textAlign: "center"}}>
                            {getTableTitle()}
                        </caption>
                        <caption style={{captionSide: "top", textAlign: "right"}} hidden={true}>GIÁO XỨ THÁNH GIUSE</caption>
                        
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Mã thiếu nhi</th>
                                <th>Tên Thánh</th>
                                <th>Họ & Tên</th>
                                <th>Lớp</th>
                                <th><b>DỰ</b> lễ thứ 3, 5 (ngày)</th>
                                <th><b>DỰ</b> lễ thứ 2,4,6 (ngày)</th>
                                <th>Tổng (ngày)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rankList.map((statisticAttendance, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>&nbsp;{statisticAttendance.userId}</td>
                                    <td>{statisticAttendance.hollyName}</td>
                                    <td>{`${statisticAttendance.lastName} ${statisticAttendance.firstName}`}</td>
                                    <td>{statisticAttendance.notes}</td>
                                    <td>{statisticAttendance.historyT3T5.length}</td>
                                    <td>{statisticAttendance.historyKhac.length}</td>
                                    <td>{statisticAttendance.numberOfWeekDayAttendance}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Row>
            }

            {rankList.length>0 ? <ReactHTMLTableToExcel
                id="attendance-ranking"
                className="download-table-xls-button"
                table="attendance-ranking"
                filename={getFileName()}
                sheet='XẾP HẠNG'
                buttonText="Xuất File Excel"/> : undefined}
            <hr/>
            <br/>
        </div>
    );
}

export default AttendanceStatisticRank;