import './App.css';
import Navbar from './components/Navbar';
import About from './components/About';
import { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Container} from 'reactstrap';
import SignIn from './components/Account/SignIn/index';
import SignUp from './components/Account/SignUp/index';
import LogOut from './components/Account/LogOut';
import UserManagement from './Admin/User';
import ClassManagement from './Admin/Classes';
import StudentInClassPresenter from './Admin/Classes/StudentInClass';

import ChangePassword from './components/Account/ChangePassword';
import ResetPassword from './components/Account/ResetPassword';
import UserDetails from './components/UserDetails';

import AcademicYearManagement from './Admin/AcademicYear';
import GradeManagement from './Admin/Grade';
import Home from './components/HomePage';
import AttendanceManagement from './Admin/Attendance/attendance.js'
import AttendanceManagementDate from './Admin/Attendance/AttendanceDateSelector.js'
import AttendanceDetails from './Admin/Attendance/AttendanceDetails';
import React from "react";
import LeaveManagement from './Admin/Leave/leave';
import CardManagement from './Admin/Card';

function App() {
  const [loginName, setloginName] = useState('')

  // Callback function for Navbar
  const getLoginName = (name) => {
    setloginName(name);
  }

  return (
    <BrowserRouter>
      <div className="App">
        <header className='App-header'>
          <h1 className="alert alert-info " align="center">
            <a href="/">
              <img alt="logo" width="80rem" src={window.location.origin + '/giuse-church-inside.jpg'}
                style={{ float: "left" }} />
            </a>
          GIÁO XỨ THÁNH GIUSE - QUẢN LÝ THIẾU NHI</h1>

        </header>
        <Navbar name={loginName} />
        <Container>
          <Switch>
            <Route exact path="/">
              <Home name={loginName} />
            </Route>

            <Route exact path="/about">
              <About />
            </Route>

            <Route exact path="/account/signin">
              <SignIn getLoginName={getLoginName} />
            </Route>

            <Route exact path="/account/signup">
              <SignUp />
            </Route>

            <Route exact path="/account/change-password/:username">
              <ChangePassword />
            </Route>

            <Route exact path="/account/logout">
              <LogOut />
            </Route>

            <Route exact path="/account/reset-password">
              <ResetPassword />
            </Route>

            <Route exact path="/admin/academic-years">
              <AcademicYearManagement />
            </Route>

            <Route exact path="/admin/grades">
              <GradeManagement />
            </Route>

            <Route exact path="/admin/users">
              <UserManagement />
            </Route>

            <Route exact path="/admin/classes/:classId">
              <StudentInClassPresenter />
            </Route>

            <Route exact path="/admin/classes">
              <ClassManagement />
            </Route>

            <Route exact path="/admin/leaves">
              <LeaveManagement />
            </Route>

            <Route exact path="/cards">
              <CardManagement/>
            </Route>

            <Route exact path="/attendances/date">
              <AttendanceManagementDate />
            </Route>

            <Route exact path="/attendances">
              <AttendanceManagement />
            </Route>

            <Route exact path="/attendances/detail/:userid">
              <AttendanceDetails />
            </Route>

            <Route exact path="/user/details/:id">
              <UserDetails />
            </Route>

            <Route path='*' exact={true} render={() => <h1>Route Not  Found</h1>} />
          </Switch>
        </Container>

        <footer className='App-footer'>
          <h6><i>Ghé fanpage giới thiếu nhi</i></h6>
          {/* <a href='https://me-qr.com/Y11dNkCN'>Link</a> */}
          <a href='https://www.facebook.com/people/Thi%E1%BA%BFu-Nhi-Th%C3%A1nh-Th%E1%BB%83-GX-Th%C3%A1nh-Giuse/100093189078767/?mibextid=LQQJ4d' target='_blank'>Link</a>
          <span> hoặc quét QR</span>
          <img alt="fanpage-qr" width="80rem" src={window.location.origin + '/fanpage-qr.jpeg'} style={{marginLeft: '1em'}}/>
          <div>
            <small>Copyright &copy; 2022 TNTT Gx.Thánh Giuse</small>
          </div>
        </footer>
      </div>
    </BrowserRouter>
  );
}

export default App;