import { useState, useEffect } from "react";
import { Col, Container, Input, Label, Row } from "reactstrap";
import "react-tabs/style/react-tabs.css";
import { useHistory } from "react-router-dom";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { endpointGlv, getWithAuth } from "../../components/HttpUtils";
import {
  addDateStrRepresentation,
  formatSyncedDate,
  getFileName,
} from "../../components/Helper";
import React from "react";
import '../../App.css'

const AttendanceDateRangeFetcher = ({useForCompetition}) => {
  const history = useHistory();
  const [attendanceList, setAttendanceList] = useState([]);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [type, setType] = useState("all");

  useEffect(() => {
    fetchAllAttendance();
  }, [startDate, endDate, type]);

  const onItemClick = (id) => {
    history.push(`/attendances/detail/${id}`);
  };

  const fetchAllAttendance = () => {
    if (startDate !== "") {
      getWithAuth(`${endpointGlv}/attendances/filter-by-date-range?startDate=${startDate}&endDate=${endDate}&type=${type}&useForCompetition=${useForCompetition}`)
        .then((response) => {
          if (response.status === 200) {
            if(!useForCompetition)
              setAttendanceList(response.data[0]);
            else
            setAttendanceList(response.data);
          }
        })
        .catch((error) =>
          console.log("Fetching attendances by date error: " + error)
        );
    }
  };

  const renderTable = (attendanceList) => {
    if(!useForCompetition){
      return(
        <Row>
          {attendanceList.length > 0 && (
            <div>
              <div style={{ float: "right" }}>
                <p>Số lượng: <b>{attendanceList.length}</b></p>
              </div>
              <table
                className="table table-hover table-secondary"
                id="attendance-by-date"
              >
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Thông tin</th>
                    <th>Ngày quét</th>
                    <th>GLV quét</th>
                    <th>Ngày đồng bộ</th>
                  </tr>
                </thead>
                <tbody>
                  {attendanceList.map((attendance, index) => (
                    <tr
                      key={index}
                      onClick={() => onItemClick(attendance.info.substring(0, 5))}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{index + 1}</td>
                      <td>{attendance.info}</td>
                      <td>{attendance.scannedDate}</td>
                      <td>{attendance.scannedBy}</td>
                      <td>{formatSyncedDate(attendance.syncedDate)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Row>
      );
    }
    else{
      return(
        <Row>
          {attendanceList.length > 0 && (
            <div>
              <div style={{ float: "right" }}>
                <p>Số lượng: <b>{attendanceList.length}</b></p>
              </div>
              <table
                className="table table-hover table-bordered"
                id="attendance-by-date"
              >
                <thead>
                  <tr>
                    <th>STT</th>
                    <th>Thông tin</th>
                    <th>Ngày quét</th>
                    <th>Tổng phiếu</th>
                  </tr>
                </thead>
                <tbody>
                  {attendanceList.map((attendance, index) => (
                    <tr
                      key={index}
                      onClick={() => onItemClick(attendance.info.substring(0, 5))}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{index + 1}</td>
                      <td>{attendance.info}</td>
                      <td>
                        <ul>
                            {attendance.attendedDates.map((date) => (<li>{addDateStrRepresentation(date)}</li>))}
                          </ul>
                        </td>
                      <td>{attendance.attendedDates.length}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Row>
      );
    }
    
  }

  return (
    <Container className="container">
      <Row>
        <Col sm="6" md="4">
          <Label for="start-date"><b>Ngày bắt đầu:</b></Label>
          <Input style={{marginLeft: "0.5rem" }} type="date"name="start-date"
              value={startDate} max={new Date().toISOString().split('T')[0]} 
              placeholder="Nhập ngày bắt đầu"
              onChange={e => setStartDate(e.target.value)}
          />
        </Col>

        <Col sm="6" md="4">
          <Label for="end-date"><b>Ngày kết thúc:</b></Label>
          <Input style={{marginLeft: "0.5rem" }} type="date"name="end-date"
              value={endDate} max={new Date().toISOString().split('T')[0]} 
              placeholder="Nhập ngày kết thúc"
              onChange={e => setEndDate(e.target.value)}
          />
        </Col>

        <Col hidden={useForCompetition}>
          <Label for="scanned-type"><b>Loại quét: </b></Label>
          <Input type="select" name="scanned-type"
              style={{marginLeft: "0.5rem" , marginBottom: "2rem"}}
              onChange={e => setType(e.target.value)}>
              <option value="all">Tất cả</option>
              <option value="T3T5">Thứ 3, Thứ 5</option>
              <option value="GiaoLy">Giáo Lý</option>
              <option value="Lesom">Lễ sớm</option>
              <option value="Khac">Khác</option>
          </Input>
        </Col>

        <br></br>
        <hr />
      </Row>

      {/* <Row>
        {attendanceList.length > 0 && (
          <div>
            <div style={{ float: "right" }}>
              <p>Số lượng: <b>{attendanceList.length}</b></p>
            </div>
            <table
              className="table table-hover table-secondary"
              id="attendance-by-date"
            >
              <thead>
                <tr>
                  <th>STT</th>
                  <th>Thông tin</th>
                  <th>Ngày quét</th>
                  <th>GLV quét</th>
                  <th>Ngày đồng bộ</th>
                  {useForCompetition && <th>Số ngày</th>}
                </tr>
              </thead>
              <tbody>
                {attendanceList.map((attendance, index) => (
                  <tr
                    key={index}
                    onClick={() => onItemClick(attendance.info.substring(0, 5))}
                    style={{ cursor: "pointer" }}
                  >
                    <td>{index + 1}</td>
                    <td>{attendance.info}</td>
                    <td>{attendance.scannedDate}</td>
                    <td>{attendance.scannedBy}</td>
                    <td>{formatSyncedDate(attendance.syncedDate)}</td>
                    {useForCompetition && <td>{findAttendanceOccurances(attendanceList, attendance.info)}</td>}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Row> */}
      {renderTable(attendanceList)};

      {attendanceList.length > 0 ? (
        <ReactHTMLTableToExcel
          id="attendance-by-date-exporter"
          className="download-table-xls-button"
          table="attendance-by-date"
          filename={getFileName()}
          sheet={`${startDate}-${endDate}-${type}`}
          buttonText="Xuất File Excel"
        />
      ) : (
        undefined
      )}
    </Container>
  );
};

export default AttendanceDateRangeFetcher;
