import React, {useState} from "react";
import { Row, Col } from "reactstrap";
import './tabs.css';

const Tabs = ({tabs, mdProp}) => {
    const [currentTab, setCurrentTab] = useState(1);

    const handleClick = (tabId) => {
        setCurrentTab(tabId);
    }

    return(
        <div>
            <Row>
                <Col md={mdProp} sm="12">
                <div>
                    <div className="tabs">
                        {tabs.map((tab, index) => 
                            <button key={index} id={tab.id} disabled={currentTab === tab.id} 
                                onClick={()=>handleClick(tab.id)}>
                                    {tab.tabTitle}
                            </button>
                        )}
                    </div>
                </div>
                </Col>
            
            </Row>

            <Row>
                <div className="content">
                    {tabs.map((tab, index) => 
                        <div key={index}>
                            {currentTab === tab.id && 
                            <div>
                                <div className="title">{tab.title}</div>
                                <div className="content">{tab.content}</div>
                            </div>}
                        </div>
                    )}
                </div>
            </Row>
        </div>
    );
}

export default Tabs;