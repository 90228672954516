import React, { useEffect, useState } from 'react';
import { endpointAdmin, getWithAuth, endpointGlv, get, endpointPublic } from '../../components/HttpUtils';
import { Container, Row, Col, Label, Button, Input } from 'reactstrap';
import { Link } from "react-router-dom";
import ModalForm from './UserModal';
import QRCodeGeneration from './QRModal';
import StatusModalForm from './ChangeStatusModal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate, getFileName } from '../../components/Helper';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactPaginate from "react-paginate";
import '../../App.css';
import './style.css'

toast.configure();
const UserManagement = () => {
    const [userList, setUserList] = useState([]);
    const [userTop5ChangeList, setUserTop5ChangeList] = useState([]);
    const [userPagingList, setUserPagingList] = useState([]);
    const [result, setResult] = useState(false);
    const [query, setQuery] = useState("");
    const [dateFilter, setDateFilter] = useState('');
    const [totalUser, setTotalUser] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [showNonResult, setShowNonResult] = useState(false);
    const [status, setStatus] = useState("");

    const itemPerPage = 40;

    const styles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    useEffect(() => {
        fetchTop5UserChangedRecently();
        getTotalUserNumber()
        fetchPagingUsers(0, 40);

    }, []);

    const getResultInModal = (resultModal) => {
        setResult(resultModal);
    }

    const fetchTop5UserChangedRecently = () => {
        getWithAuth(`${endpointAdmin}/users/changes-recently`).then((response) => {
            if (response.status === 200) {
                setUserTop5ChangeList(response.data);
            }
        }).catch((error) => console.log("Query users error: " + error))
    }

    const queryUser = (queryVal, dateVal) => {
        if (queryVal.trim() === '' && dateVal === '' && status === '')
            return;

        let queryStr = '';
        let params = {};
        if (queryVal.trim() !== '') {
            params['info'] = queryVal.trim();
        }
        if (dateVal !== '') {
            params['date'] = dateVal;
        }
        if (status !== '') {
            params['status'] = status;
        }

        queryStr = Object
            .entries(params) // return an array of enumerable string-keyed property key-value pairs.
            .map(arrItem => arrItem.join('=')) // param1=val1, param2=val2
            .join('&'); // param1=val1&param2=val2

        getWithAuth(`${endpointGlv}/query-user?${queryStr}`).then((response) => {
            if (response.status === 200) {
                if (response.data.length === 0) {
                    setShowNonResult(true)
                }
                else setShowNonResult(false)

                setUserList(response.data);
            }
        }).catch((error) => console.log("Query users error: " + error))
    }

    const fetchPagingUsers = async (currentPage, itemPerPage) => {
        await getWithAuth(`${endpointAdmin}/users/paging-fields-filtering/${currentPage}/${itemPerPage}`).then((response) => {
            if (response.status === 200) {
                setUserPagingList(response.data);
            }
        }).catch((error) => console.log("Query paging users error: " + error))
    }

    const getTotalUserNumber = () => {
        get(`${endpointPublic}/users/total-number`).then((response) => {
            if (response.status === 200) {
                let totalUsers = parseInt(response.data)
                setTotalUser(totalUsers);
                setPageCount(Math.ceil(totalUsers / itemPerPage))
            }
        }).catch((error) => console.log("Query paging users error: " + error))
    }

    const onSearching = (event) => {
        let query = event.target.value.toLowerCase().trim();
        setQuery(query);
    }

    const handlePageClick = async (data) => {
        let currentPage = data.selected;
        fetchPagingUsers(currentPage, itemPerPage).then(() => window.document.getElementById("paging-users").scrollIntoView({ behavior: "smooth" }));
    };

    const insertToTopUserList = (newUser, isUpdate) => {
        if (isUpdate) {
            // Find in user list
            // const index = userList.map(function (item) {
            //     return item.userId
            // }).indexOf(newUser.userId);
            // userList.splice(index, 1);

            // Find in top 5 user list
            const indexTop5 = userList.map(function (item) {
                return item.userId
            }).indexOf(newUser.userId);
            userTop5ChangeList.splice(indexTop5, 1);
        }
        // const newUserList = [newUser, ...userList];
        // setUserList(newUserList);

        const newTop5UserList = [newUser, ...userTop5ChangeList]
        setUserTop5ChangeList(newTop5UserList);
    }

    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    }

    return (
        <div style={{ marginBottom: "10rem" }}>
            <Container>
                <Row style={{ marginTop: "2rem" }}>
                    <h3 className="alert alert-warning" align="center" style={{ width: "100%" }}>QUẢN LÝ NGƯỜI DÙNG</h3>
                    <Col sm="10" md="10"></Col>
                    <Col>
                        <ModalForm style={{ marginTop: "1rem" }}
                            buttonLabel="Thêm mới người dùng"
                            className="modal-content"
                            title="Thêm mới người dùng"
                            color="success"
                            userId=""
                            getResultInModal={() => getResultInModal()}
                            insertable={true}
                            onMergeUser={insertToTopUserList}>
                            Thêm mới người dùng</ModalForm>
                    </Col>

                </Row>

                <Row>
                    <Col sm="12" md="6">
                        <strong>
                            <Label for="search">Tra cứu</Label>
                        </strong>
                        <br></br>
                        <input
                            type="search"
                            name="search"
                            style={{ width: "100%", height: "2.5rem" }}
                            placeholder="Nhập mã thiếu nhi, tên thánh, họ tên thiếu nhi, SĐT phụ huynh, họ tên phụ huynh,..."
                            onChange={onSearching}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    e.preventDefault();
                                    queryUser(e.target.value, dateFilter)
                                }
                            }}
                        />
                        {showNonResult && <p style={{ color: "blue" }}>Thông tin không tồn tại</p>}
                        {(userList.length > 0) && <p style={{ float: "right" }}>Kết quả tìm được: <b>{userList.length}</b></p>}
                    </Col>

                    <Col sm="12" md="3">
                        <div style={{ display: "inline-block" }}>
                            <strong>Tình trạng</strong>
                            <Input type="select" name="status" id="status"
                                onChange={e => handleStatusChange(e)}>
                                <option key={0} value={""}>---------------------------------</option>
                                <option key={1} value={"KHÔNG HOẠT ĐỘNG"}>KHÔNG HOẠT ĐỘNG</option>
                                <option key={2} value={"NGHỈ HỌC NGANG"}>NGHỈ HỌC NGANG</option>
                                <option key={3} value={"ĐÃ CHUYỂN XỨ"}>ĐÃ CHUYỂN XỨ</option>
                                <option key={4} value={"ĐÃ TỐT NGHIỆP"}>ĐÃ TỐT NGHIỆP</option>
                            </Input>
                        </div>
                    </Col>

                    <Col sm="12" md="3">
                        <div style={{ display: "inline-block" }}>
                            <strong>Chọn ngày sinh/ rửa tội: </strong>
                            <Input
                                style={{ width: "10rem", marginLeft: "1rem" }}
                                type="date"
                                onChange={(e) => setDateFilter(e.target.value)}
                            />
                        </div>

                        <Button color="dark" onClick={() => queryUser(query, dateFilter)}>Tìm</Button>
                    </Col>
                </Row>
                {userList.length > 0 &&
                    <div>
                        <div className='wrapped-table'>
                            <table className="table table-hover table-light" id="users">
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>ID</th>
                                        <th>Tên Thánh</th>
                                        <td>Họ và tên đệm</td>
                                        <th>Tên</th>
                                        <th>Ngày sinh</th>
                                        <th>Ngày rửa tội </th>
                                        <th>Lớp</th>
                                        <th>Họ tên cha</th>
                                        <th>Họ tên mẹ</th>
                                        <th>Liên hệ</th>
                                        <th>Giáo họ</th>
                                        <th>Tình trạng</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody id="table-body">
                                    {userList.map((user, index) => (
                                        <tr key={index} id={"row-" + index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link to={{ pathname: `/user/details/` + user.user_id_result }}
                                                    target="_blank">&nbsp;{user.user_id_result}
                                                </Link>
                                            </td>
                                            <td>{user.holly_name_result}</td>
                                            <td>{user.last_name_result}</td>
                                            <td>{user.first_name_result}</td>
                                            <td>{formatDate(user.birthday_result)}</td>
                                            <td>{formatDate(user.christen_date_result)}</td>
                                            <td>{user.class_id_result}</td>
                                            <td>{user.father_name_result}</td>
                                            <td>{user.mother_name_result}</td>
                                            <td>
                                                <ul>
                                                    {user.dad_phone && <li>&nbsp;{user.dad_phone}</li>}
                                                    {user.mom_phone && <li>&nbsp;{user.mom_phone}</li>}
                                                </ul>
                                            </td>
                                            <td>{user.region_result}</td>
                                            <td>{user.status}</td>

                                            <td><ModalForm
                                                buttonLabel="Sửa"
                                                className="modal-content"
                                                title="Sửa"
                                                color="black"
                                                userId={user.user_id_result}
                                                getResultInModal={() => getResultInModal()}
                                                insertable={false}
                                                onMergeUser={insertToTopUserList}>
                                            </ModalForm></td>

                                            <td>
                                                <QRCodeGeneration
                                                    title="Tạo mã QR Code"
                                                    color="orange"
                                                    userId={user.user_id_result}
                                                    hollyName={user.holly_name_result}
                                                    firstName={user.first_name_result}
                                                    lastName={user.last_name_result}>
                                                </QRCodeGeneration>
                                            </td>

                                            <td><StatusModalForm
                                                buttonLabel="Cập nhật"
                                                title="Cập nhật tình trạng"
                                                color="green"
                                                userId={user.user_id_result}
                                                birthday={user.birthday_result}
                                                christenDate=""
                                                confirmationDate=""
                                                phoneNumberInput=""
                                                addressInput=""
                                                genderInput=""
                                                imageInput={user.image}
                                                roleInput={user.roles}
                                                getResultInModal={() => getResultInModal()}
                                                insertable={false}>
                                            </StatusModalForm></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div style={{ float: 'right' }}>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="users"
                                filename={getFileName()}
                                sheet="data"
                                buttonText="Xuất File Excel" />
                        </div>

                        {/* <ExportExcel excelData={userList} fileName={getFileName()}/> */}
                    </div>
                }

                <br />
                <hr />
                <br />

                <h3>THAY ĐỔI GẦN ĐÂY</h3>
                {userTop5ChangeList.length > 0 &&
                    <div className='wrapped-table'>
                        <table className="table table-hover table-light" id="users">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>ID</th>
                                    <th>Tên Thánh</th>
                                    <td>Họ và tên đệm</td>
                                    <th>Tên</th>
                                    <th>Ngày sinh</th>
                                    <th>Ngày rửa tội </th>
                                    <th>Họ tên cha</th>
                                    <th>Họ tên mẹ</th>
                                    <th>SDT cha</th>
                                    <th>SDT mẹ</th>
                                    <th>Tình trạng</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody id="table-body">
                                {userTop5ChangeList.map((user, index) => (
                                    <tr key={user.userId} id={"row-" + user.userId}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <Link to={{ pathname: `/user/details/` + user.userId }} target="_blank">&nbsp;{user.userId}</Link>
                                        </td>
                                        <td>{user.hollyName}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.firstName}</td>
                                        <td>{formatDate(user.birthday)}</td>
                                        <td>{formatDate(user.christenDate)}</td>
                                        <td>{user.fatherName}</td>
                                        <td>{user.motherName}</td>
                                        <td>&nbsp;{user.dadPhoneNumber}</td>
                                        <td>&nbsp;{user.momPhoneNumber}</td>
                                        <td>{user.status}</td>

                                        <td><ModalForm
                                            className="modal-content"
                                            buttonLabel="Sửa"
                                            title="Sửa"
                                            color="black"
                                            userId={user.userId}
                                            birthday={user.birthday}
                                            christenDate=""
                                            confirmationDate=""
                                            phoneNumberInput=""
                                            addressInput=""
                                            genderInput=""
                                            imageInput={user.image}
                                            roleInput={user.roles}
                                            getResultInModal={() => getResultInModal()}
                                            insertable={false}
                                            currentUser={user}
                                            onMergeUser={insertToTopUserList}>
                                        </ModalForm></td>

                                        <td>
                                            <QRCodeGeneration
                                                title="Tạo mã QR Code"
                                                color="orange"
                                                userId={user.userId}
                                                hollyName={user.hollyName}
                                                firstName={user.firstName}
                                                lastName={user.lastName}>
                                            </QRCodeGeneration>
                                        </td>

                                        <td><StatusModalForm
                                            buttonLabel="Cập nhật"
                                            title="Cập nhật tình trạng"
                                            color="green"
                                            userId={user.userId}
                                            birthday={user.birthday}
                                            christenDate=""
                                            confirmationDate=""
                                            phoneNumberInput=""
                                            addressInput=""
                                            genderInput=""
                                            imageInput={user.image}
                                            roleInput={user.roles}
                                            getResultInModal={() => getResultInModal()}
                                            insertable={false}>
                                        </StatusModalForm></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}

                <br />
                <hr />
                <br />

                <h2>TOÀN BỘ DANH SÁCH</h2>
                <p style={{ float: 'right' }}>Tổng số lượng: <strong>{totalUser}</strong></p>
                <br />
                {userPagingList.length > 0 &&
                    <div>
                        <div className='wrapped-table'>
                            <table className="table table-hover table-light" id="paging-users">
                                <thead>
                                    <tr>
                                        <th>STT</th>
                                        <th>ID</th>
                                        <th>Tên Thánh</th>
                                        <td>Họ và tên đệm</td>
                                        <th>Tên</th>
                                        <th>Ngày sinh</th>
                                        <th>Ngày rửa tội </th>
                                        <th>Họ tên cha</th>
                                        <th>Họ tên mẹ</th>
                                        <th>SDT cha</th>
                                        <th>SDT mẹ</th>
                                        <th>Tình trạng</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody id="table-body">
                                    {userPagingList.map((user, index) => (
                                        <tr key={user.userId} id={"row-" + user.userId}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <Link to={{ pathname: `/user/details/` + user.userId }} target="_blank">&nbsp;{user.userId}</Link>
                                            </td>
                                            <td>{user.hollyName}</td>
                                            <td>{user.lastName}</td>
                                            <td>{user.firstName}</td>
                                            <td>{formatDate(user.birthday)}</td>
                                            <td>{formatDate(user.christenDate)}</td>
                                            <td>{user.fatherName}</td>
                                            <td>{user.motherName}</td>
                                            <td>&nbsp;{user.dadPhoneNumber}</td>
                                            <td>&nbsp;{user.momPhoneNumber}</td>
                                            <td>{user.status}</td>

                                            <td><ModalForm
                                                buttonLabel="Sửa"
                                                className="modal-content"
                                                title="Sửa"
                                                color="black"
                                                userId={user.userId}
                                                birthday={user.birthday}
                                                christenDate=""
                                                confirmationDate=""
                                                phoneNumberInput=""
                                                addressInput=""
                                                genderInput=""
                                                imageInput={user.image}
                                                roleInput={user.roles}
                                                getResultInModal={() => getResultInModal()}
                                                insertable={false}
                                                currentUser={user}
                                                onMergeUser={insertToTopUserList}>
                                            </ModalForm></td>

                                            <td>
                                                <QRCodeGeneration
                                                    title="Tạo mã QR Code"
                                                    color="orange"
                                                    userId={user.userId}
                                                    hollyName={user.hollyName}
                                                    firstName={user.firstName}
                                                    lastName={user.lastName}>
                                                </QRCodeGeneration>
                                            </td>

                                            <td><StatusModalForm
                                                buttonLabel="Cập nhật"
                                                title="Cập nhật tình trạng"
                                                color="green"
                                                userId={user.userId}
                                                birthday={user.birthday}
                                                christenDate=""
                                                confirmationDate=""
                                                phoneNumberInput=""
                                                addressInput=""
                                                genderInput=""
                                                imageInput={user.image}
                                                roleInput={user.roles}
                                                getResultInModal={() => getResultInModal()}
                                                insertable={false}>
                                            </StatusModalForm></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        <div style={{float: 'right'}}>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-paging"
                                className="download-table-xls-button"
                                table="paging-users"
                                filename={getFileName()}
                                sheet="data"
                                buttonText="Xuất File Excel" />
                        </div>

                        <br/>
                        <br/>
                            <ReactPaginate
                                previousLabel={"<<"}
                                nextLabel={">>"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                    </div>}
            </Container>
        </div>
    );
}

export default UserManagement;