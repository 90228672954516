import React, {useState, useEffect} from "react";
import { Row, Col } from "reactstrap";
import {Buffer} from 'buffer';
import { formatDate } from "../Helper";
import { endpointPublic, endpointUser, getBinaryValue, getWithAuth } from "../HttpUtils";

const InfoDetails = ({userId}) => {
    const [user, setUser] = useState({});

    useEffect(() => {
        fetchUser();
        getQrCode();
        document.body.scrollIntoView({behavior: "smooth"});
    }, []);

    const fetchUser = () => {
        getWithAuth(endpointUser + "/" + userId).then((response) => {
            if(response.status === 200){
                setUser(response.data);
            }
        }).catch((error) => console.log("Fetching user details error: " + error))
    }

    const getQrCode = () => {
        getBinaryValue(endpointPublic + "/generate-qr-code-image/" + userId).then((response) => {
            if (response.status === 200) {
                let base64ImageString = Buffer.from(response.data, 'binary').toString('base64')
                let srcValue = "data:image/png;base64,"+base64ImageString
                document.getElementById('qr-image-user-details').src = srcValue
            }
        }).catch((error) => console.log("Creating user's QR Code: " + error))
    }

    return(
        <div>
            <Row>
                <h3 className="alert alert-primary" align="center">THÔNG TIN CHI TIẾT</h3>
            </Row>

            <Row>
                <Col sm="6" md="4">
                    <div style={{margin:"auto", width:"50%"}}>
                        {(user.image !== null) ?
                            <img src={`data:image/jpeg;base64,${user.image}`} width="100%" height="100%" style={{objectFit: 'contain'}}></img>
                            :
                            <img src={window.location.origin + '/user-default.jpg'} width="250" height="250"></img>
                        }
                    </div>
                </Col>

                <Col>
                    <table className='table table-bordered'>
                        <tr>
                            <th>Mã thiếu nhi</th>
                            <td>{user.userId}</td>
                        </tr>
                        <tr>
                            <th>Tên Thánh</th>
                            <td>{user.hollyName}</td>
                        </tr>
                        <tr>
                            <th>Họ, tên đệm</th>
                            <td>{user.lastName}</td>
                        </tr>
                        <tr>
                            <th>Tên</th>
                            <td>{user.firstName}</td>
                        </tr>
                        <tr>
                            <th>Tên Cha</th>
                            <td>{user.fatherName}</td>
                        </tr>
                        <tr>
                            <th>Tên Mẹ</th>
                            <td>{user.motherName}</td>
                        </tr>
                        <tr>
                            <th>SĐT Cha</th>
                            <td>{user.dadPhoneNumber}</td>
                        </tr>
                        <tr>
                            <th>SĐT Mẹ</th>
                            <td>{user.momPhoneNumber}</td>
                        </tr>
                        <tr>
                            <th>Địa chỉ</th>
                            <td>{user.address}</td>
                        </tr>
                        <tr>
                            <th>Giáo họ</th>
                            <td>{user.region}</td>
                        </tr>
                        <tr>
                            <th>Ngày sinh</th>
                            <td>{formatDate(user.birthday)}</td>
                        </tr>
                        <tr>
                            <th>Ngày Rửa Tội</th>
                            <td>{formatDate(user.christenDate)}</td>
                        </tr>
                        <tr>
                            <th>Ngày Nhập học</th>
                            <td>{formatDate(user.startDate)}</td>
                        </tr>
                        <tr>
                            <th>Ngày tốt nghiệp</th>
                            <td>{formatDate(user.endDate)}</td>
                        </tr>
                        <tr>
                            <th>Tình trạng</th>
                            <td>{user.status}</td>
                        </tr>
                        <tr>
                            <th>Thông tin phụ</th>
                            <td>{user.notes}</td>
                        </tr>
                    </table>
                </Col>

                <Col md="2" sm="2">
                    <div style={{float: 'right'}}>
                        <img id='qr-image-user-details' width={150} height={150}></img>
                    </div>
                </Col>
            </Row>
            <hr/>
        </div>
    );
}

export default InfoDetails;