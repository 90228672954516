import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { Col, Container, Input, Label, Row } from 'reactstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { toast } from 'react-toastify';
import {RiCloseCircleLine} from 'react-icons/ri';
import LeaveModal from "./LeaveModal";
import { getWithAuth, deleteWithAuth, endpointAdmin } from '../../components/HttpUtils';
import { formatDate, getFileName, styles } from "../../components/Helper";
import { messages } from "../../components/message";
import LeaveEditModal from "./LeaveEditModal";
import AllowCommonLeave from "./AllowCommonLeave";
import './styles.css';

toast.configure();
const LeaveListWithType = () => {
    const [loading, setLoading] = useState(false);
    const [leaveList, setLeaveList] = useState([]);
    const [leaveType, setLeaveType] = useState('');

    useEffect(() => {
        
    }, [leaveList]);

    const fetchLeaveByType = (type) => {
        setLoading(true);
        getWithAuth(`${endpointAdmin}/leaves/current?leaveTypeId=${type}`)
            .then((response) => {
            if (response.status === 200) {
                setLeaveList(response.data);
                setLoading(false);
            }
        }).catch((error) => console.log("Fetching Leaves error: " + error))
    }

    const handleFilterChange = (e) => {
        setLeaveType(e.target.value);
        fetchLeaveByType(e.target.value);
    }

    const getLeaveName = (leaveType) => {
        if(leaveType === 'N35'){
            return "Nghỉ lễ thứ 3, thứ 5"
        }
        else if(leaveType === 'NGL'){
            return "Nghỉ học giáo lý"
        }
    }

    const deleteLeave = (id) => {
        if (window.confirm(messages.deleteConfirm)) {
            deleteWithAuth(endpointAdmin + "/leaves/" + id).then((response) => {
                if (response.status === 200) {
                    toast.success(messages.deleteSuccess, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                    });

                    let tempList = [...leaveList]
                    tempList = tempList.filter(item => item.leave_id_result !== id);
                    setLeaveList(tempList);
                }
            }).catch(error => {
                if (error.response) {
                    toast.error(messages.deleteFailed, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1000,
                    });
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
                console.log("Delete grade error: " + error);
            })
        } else {
            // Do nothing!
        }
    }

    const insertToTopLeaveList = (newLeave, isUpdate) => {
        if(isUpdate){
            // Find in leave list
            const index = leaveList.map(function (item) {
                return item.leave_id_result
            }).indexOf(newLeave.leaveId);
            leaveList.splice(index, 1);

            const data = {
                "reason_result": newLeave.reason,
                "compensate_date_result": newLeave.compensateDate,
                "absent_date_result": newLeave.absentDate,
                "notes_result": newLeave.notes,
                "leave_type_id_result": newLeave.leaveTypeId,
                "academic_year_id_result": newLeave.academicYearId,
                "leave_id_result": newLeave.leaveId,
                "user_id_result": newLeave.userId,
                "full_name": newLeave.fullName
            }
            const newLeaveList = [data, ...leaveList]
            setLeaveList(newLeaveList);
        }
        else{
            let addedLeaves = [];
            for(const leave of newLeave){
                let data = {
                    "reason_result": leave.reason,
                    "compensate_date_result": leave.compensateDate,
                    "absent_date_result": leave.absentDate,
                    "notes_result": leave.notes,
                    "leave_type_id_result": leave.leaveTypeId,
                    "academic_year_id_result": leave.academicYearId,
                    "leave_id_result": leave.leaveId,
                    "user_id_result": leave.userId,
                    "full_name": leave.fullName
                }
                addedLeaves.push(data);
            }
            const newLeaveList = addedLeaves.concat(...leaveList);
            setLeaveList(newLeaveList)
        }
    }

    return(
        <Container>
            <Row>
                <Col style={{float: 'right'}}>
                        <div style={{ float: "right" , display:"flex"}}>
                            <LeaveModal
                                buttonLabel="Thêm mới"
                                className="modal-content"
                                title="Thêm mới nghỉ phép"
                                color="success"
                                leaveId=""
                                insertable={true}
                                onMergeLeave={insertToTopLeaveList}
                            >
                            Thêm mới</LeaveModal>
                            <span style={{marginLeft: '0.1rem'}}/>
                            <AllowCommonLeave
                                buttonLabel="Nghỉ lễ"
                                className="modal-content"
                                title="Những ngày được nghỉ trong năm học"
                                color="warning"
                                leaveId=""
                                insertable={true}
                                onMergeLeave={insertToTopLeaveList}
                            >
                            Nghỉ lễ</AllowCommonLeave>
                        </div>
                    </Col>
            </Row>
                <Row>
                    <Label for="class-academic-year"><b>Chọn loại phép: </b></Label>
                    <Input type="select" name="class-academic-year"
                        style={{ width: "20rem" }}
                        onChange={e => handleFilterChange(e)}>
                            <option key='' value=''>------CHỌN LOẠI PHÉP-----</option>
                            <option key='N35' value='N35'>NGHỈ LỄ THỨ 3, THỨ 5</option>
                            <option key='NGL' value='NGL'>NGHỈ PHÉP CHÚA NHẬT</option>
                    </Input>
                </Row>

                <Row>
                    {leaveList.length > 0 ?
                        <div >
                            <p style={{float: 'right'}}>Tổng số lượng: <strong>{leaveList.length}</strong></p>
                        </div>
                        : undefined
                    }
                </Row>
                
                <Row>
                    <div style={styles}>
                    <ScaleLoader
                        color="brown"
                        loading={loading}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                    </div>
                </Row>

                {leaveList.length > 0 &&
                    <Row style={{marginTop: '1rem'}}>
                        <div style={{overflowX: 'scroll'}} >
                        <table className="table table-hover table-bordered" id='leaves-table'>
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>ID</th>
                                    <th>Tên Thánh & Họ tên</th>
                                    <th>Lớp</th>
                                    <th>Loại phép</th>
                                    <th>Lý do vắng</th>
                                    {leaveType === 'NGL' && <th>Ngày vắng</th>}
                                    {leaveType === 'N35' && <th>Ngày đi bù</th>}
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaveList.map((leave, index) => (
                                    <tr key={leave.leave_id_result}>
                                        <td>{index+1}</td>
                                        <td>{leave.user_id_result}</td>
                                        <td>{leave.full_name}</td>
                                        <td>{leave.class_id_result}</td>
                                        <td>{getLeaveName(leave.leave_type_id_result)}</td>
                                        <td>{leave.reason_result}</td>
                                        {leaveType === 'NGL' && <td>{formatDate(leave.absent_date_result)}</td>}
                                        {leaveType === 'N35' && <td>{leave.compensate_date_result}</td>}
                                        <td style={{textAlign: 'center'}}>
                                            <LeaveEditModal
                                                buttonLabel="Sửa"
                                                title="Sửa thông tin nghỉ phép"
                                                color="info"
                                                leaveId={leave.leave_id_result}
                                                onMergeLeave={insertToTopLeaveList}>
                                            </LeaveEditModal>
                                        </td>
                                        <td style={{textAlign: 'center'}}>
                                            <RiCloseCircleLine color="red" style={{cursor: 'pointer'}} 
                                                    onClick={() => deleteLeave(leave.leave_id_result)}/>   
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        </div>
                    </Row>
                }

                {leaveList.length > 0 && 
                <div style={{float:'right', marginBottom: '4rem'}}>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-paging"
                        className="download-table-xls-button"
                        table="leaves-table"
                        filename={getFileName()}
                        sheet={`NGHỈ_PHÉP_${leaveType}`}
                        buttonText="Xuất File Excel"/>
                    </div>
                }

            </Container>
    );
}

export default LeaveListWithType;