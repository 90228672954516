import React, {useState, useEffect} from "react";
import { Col, CustomInput, Input, Label, Row } from "reactstrap";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { addDateStrRepresentation, getFileName } from "../../components/Helper";
import { get, endpointPublic, endpointGlv, getWithAuth } from "../../components/HttpUtils";
import { Link } from "react-router-dom";
import ScaleLoader from "react-spinners/ScaleLoader";

const AttendanceStatisticInMonth = () => {
    const [classList, setClassList] = useState([]);
    const [filterClass, setFilterClass] = useState("");
    const [month, setMonth] = useState(0);
    const [attendanceList, setAttendanceList] = useState([]);
    const [historyChecked, setHistoryChecked] = useState(false);
    const [activeAcademicYearName, setActiveAcademicYearName] = useState('');
    const [loading, setLoading] = useState(false);

    const styles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    useEffect(() => {
        getCurrentClasses();
        getCurrentAcademicYear();
    }, []);

    const getCurrentClasses = () => {
        get(endpointPublic + "/classes/current").then((response) => {
            if (response.status === 200) {
                setClassList(response.data);
            }
        }).catch((error) => console.log("Fetching current classes error: " + error))
    }

    const getCurrentAcademicYear = () => {
        get(endpointPublic + "/active-academic-year").then((response) => {
            if (response.status === 200) {
                setActiveAcademicYearName(response.data.name);
            }
        }).catch((error) => console.log("Fetching current classes error: " + error))
    }

    const handleFilterChange = (event) => {
        setFilterClass(event.target.value);
        fetchAttendanceByClassIdInMonth(event.target.value, month).then(()=>setLoading(false));
    }

    const fetchAttendanceByClassIdInMonth = async (classId, month) => {
        if(classId !== "" && month > 0 && month !== undefined){
            setLoading(true);
            await getWithAuth(endpointGlv + "/statistic-by-class-by-month/" + classId + "/" + month).then((response) => {
                if (response.status === 200) {
                    setAttendanceList(response.data);
                }
            }).catch((error) => console.log("Fetching statistic error: " + error))
        }
    }

    const handleHistoryCheckboxChanged = (event) => {
        setHistoryChecked(event.target.checked);
    }

    const processLeaveInfoDetails = (info) =>{
        let details = info.split("\n")
        if(details.length > 1){
            return <p>{details[0] + " - "} <b>{details[1]}</b></p>
        }
        else
            return <p>{details[0]}</p>
    }

    const renderTableData = (attendanceList) =>{
        if(historyChecked){
            return attendanceList.map((attendance, index) => {
                return(
                    <tr>
                        <td>{index+1}</td>
                         <td>
                            <Link to={{ pathname: `/attendances/detail/` + attendance.userId }} target="_blank">&nbsp;{attendance.userId}</Link>
                        </td>
                        <td>{attendance.hollyName}</td>
                        <td>{attendance.lastName}</td>
                        <td>{attendance.firstName}</td>
                        <td>
                            <ul>
                                {attendance.historyT3T5.map((date) => (<li>{addDateStrRepresentation(date)}</li>))}
                            </ul>
                        </td>
                        <td>
                            <ul>
                                {attendance.historyGiaoLy.map((date) => (<li>{date}</li>))}
                            </ul>
                        </td>
                        <td>
                            <ul>
                                {attendance.historyLeSom.map((date) => (<li>{date}</li>))}
                            </ul>
                        </td>
                        <td>
                            <ul>
                                {attendance.historyKhac.map((date) => (<li>{addDateStrRepresentation(date)}</li>))}
                            </ul>
                        </td>
                        <td>
                            {processLeaveInfoDetails(attendance.notes)}
                        </td>
                    </tr>
                );
            });
        }
        else{
            return attendanceList.map((attendance, index) => {
                return(
                    <tr>
                        <td>{index+1}</td>
                         <td>
                            <Link to={{ pathname: `/attendances/detail/` + attendance.userId }} target="_blank">&nbsp;{attendance.userId}</Link>
                        </td>
                        <td>{attendance.hollyName}</td>
                        <td>{attendance.lastName}</td>
                        <td>{attendance.firstName}</td>
                        <td>{attendance.historyT3T5.length}</td>
                        <td>{attendance.historyGiaoLy.length}</td>
                        <td>{attendance.historyLeSom.length}</td>
                        <td>{attendance.historyKhac.length}</td>
                        <td>
                            {processLeaveInfoDetails(attendance.notes)}
                        </td>
                    </tr>
                );
            });
        }
    }

    return(
        <div>
            <Row>
                <Col>
                    <Label for="class-academic-year"><b>Chọn lớp: </b></Label>
                    <Input type="select" name="class-academic-year"
                        style={{ width: "20rem", height:"2.2rem" }}
                        onChange={e => handleFilterChange(e)}>
                        <option>---------------Lớp học---------------</option>
                        {classList.map((currentClass) => (
                            <option key={currentClass.classId} 
                                    value={currentClass.classId}
                                    onChange={(e)=>setFilterClass(e.target.value)} >{currentClass.name}</option>
                        ))}
                    </Input>
                </Col>

                <Col>
                    <Label for="input-month"><b>Chọn tháng: </b></Label>
                    <Input style={{ width: "10rem", height:"2.2rem" }} type="number" step={1} name="input-month"
                        min={1} max={12} 
                        placeholder="Nhập tháng"
                        onChange={e => setMonth(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.code === "Enter") {
                            e.preventDefault();
                            fetchAttendanceByClassIdInMonth(filterClass, e.target.value).then(()=>setLoading(false))
                            }
                        }}
                    />
                </Col>

                <Col>
                    <CustomInput type="checkbox" id="historyCheckbox" name="historyCheckbox" 
                        defaultChecked={historyChecked} label="Hiển thị lịch sử"
                        checked={historyChecked} onChange={(e) => handleHistoryCheckboxChanged(e)} />
                    
                </Col>
            </Row>

            <Row>
                <div style={styles}>
                <ScaleLoader
                    color="brown"
                    loading={loading}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
                </div>
            </Row>

            {attendanceList.length > 0 && 
                <Row>
                    <table style={{marginTop: '1rem'}} className="table table-hover table-bordered" id="attendance-checking">
                        <caption style={{captionSide: "top", textAlign: "center"}}>
                            <h4>TỔNG KẾT ĐIỂM DANH TRONG THÁNG {month} - LỚP {filterClass.substring(2)} ({activeAcademicYearName})</h4>
                        </caption>
                        <caption style={{captionSide: "top", textAlign: "right"}} hidden={true}>GIÁO XỨ THÁNH GIUSE</caption>
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>ID</th>
                                <th>Tên Thánh</th>
                                <th>Họ và tên đệm</th>
                                <th>Tên</th>
                                <th>Lễ thứ 3, 5</th>
                                <th>Học giáo lý</th>
                                <th>Đi lễ sớm</th>
                                <th>Khác</th>
                                <th>Ghi chú</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderTableData(attendanceList)}
                        </tbody>
                    </table>
                </Row>
            }

            {attendanceList.length>0 ? <ReactHTMLTableToExcel
                id="attendance-checking"
                className="download-table-xls-button"
                table="attendance-checking"
                filename={getFileName()}
                sheet={filterClass}
                buttonText="Xuất File Excel"/> : undefined}
        </div>
    );
}

export default AttendanceStatisticInMonth;