import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody} from 'reactstrap';
import { get, endpointPublic } from '../../components/HttpUtils';
import { toast } from 'react-toastify';
import ScaleLoader from "react-spinners/ScaleLoader";
import 'react-toastify/dist/ReactToastify.css';
import { styles } from '../../components/Helper';

toast.configure();
const AllowCommonLeave = (props) => {
    const {
        buttonLabel,
        className,
        title,
        color,
    } = props;

    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [leaveList, setLeaveList] = useState([]);

    const toggle = () => {
        setModal(!modal);
        if(!modal){
            getAllowCommonLeaveList();
        }
    }

    const getAllowCommonLeaveList = () => {
        setLoading(true);
        get(`${endpointPublic}/allow-common-leave-dates`).then((res) => {
            if(res.status === 200){
                setLeaveList(res.data.items);
                console.log("Common: ", res.data.items);
            }
        })
        .catch((e) => console.log("Fetch allow common leave dates error: ", e))
        .finally(() => setLoading(false));
    }

    return (
        <div>
            <Button color={color} onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <b><u>(*) Những ngày này không tính vắng</u></b>
                    <div style={styles}>
                        <ScaleLoader
                            color="brown"
                            loading={loading}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    {leaveList.length > 0 && 
                        <table style={{marginTop: '1rem'}} className="table table-hover table-bordered" id="attendance-checking">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Ngày nghỉ</th>
                                    <th>Lý do nghỉ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaveList.map((leave, index) => (
                                    <tr key={index} id={index} >
                                        <td>{index+1}</td>
                                        <td>{leave.info}</td>
                                        <td>{leave.scannedDate}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </ModalBody>
            </Modal>
            
        </div>
    );
}

export default AllowCommonLeave;