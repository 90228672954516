import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import { endpointAdmin, endpointPublic, get, hostFrontend, postwithAuth, putWithAuth } from '../../components/HttpUtils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { messages } from '../../components/message';

toast.configure();
const AcademicYearModal = (props) => {
    const {
        buttonLabel,
        className,
        title,
        color,
        academicYearId,
        insertable
    } = props;

    const [academicYear, setAcademicYear] = useState({})
    const [modal, setModal] = useState(false);
    const [errors, setErrors] = useState({});

    const toggle = () => setModal(!modal);

    useEffect(()=>{
        fetchAcademicYear(academicYearId)
    }, []);

    const fetchAcademicYear = (id) => {
        get(endpointPublic + "/academic-years/" + id).then((response) => {
            if (response.status === 200) {
                setAcademicYear(response.data);
            }
        }).catch((error) => console.log("Fetching academic year error: " + error))
    }

    const updateAcademicYear = (e) => {
        e.preventDefault();

        if (!validateForm(academicYear.academicYearId, academicYear.name))
            return;

        if (insertable) {
            academicYear['classIds'] = []
            postwithAuth(endpointAdmin + "/academic-years", academicYear).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    console.log("Insert new AcademicYear successfully!");

                    toast.success(messages.insertSuccess, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });
                    
                    setTimeout(function () {
                        window.location.replace(hostFrontend + "admin/academic-years");
                    }, 2500);
                    toggle();
                }
            }).catch(error => {
                toast.error(messages.insertFailed, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                console.log("error inserting new AcademicYear: " + error);
            })
        }
        else {
            putWithAuth(endpointAdmin + "/academic-years", academicYear).then((response) => {
                if (response.status === 200) {
                    console.log(messages.updateSuccess);
                    toast.success(messages.updateSuccess, {
                        position: toast.POSITION.TOP_RIGHT,
                        autoClose: 2000,
                    });

                    setTimeout(function () {
                        toggle();
                    }, 2000);
                }
            }).catch(error => {
                toast.error(messages.insertFailed, {
                    position: toast.POSITION.TOP_RIGHT,
                    autoClose: 2000,
                });
                console.log("error updating AcademicYear: " + error);
            })
        }
    }

    const validateForm = (inp_id, inp_name) => {
        let errors = {}, formIsValid = true;
        if (inp_id.length < 3 || inp_id.length > 9) {
            errors["id"] = messages.categoryIdLength;
            formIsValid = false;
        }
        if (inp_name.length < 3 || inp_name.length > 50) {
            errors["name"] = messages.AcademicYear_ManufacturerNameLength;
            formIsValid = false;
        }
        setErrors(errors);

        return formIsValid;
    }

    const setAcademicYearAttr = (key, value) => {
        var tempAcademicYear = Object.assign({}, academicYear);
        tempAcademicYear[key] = value
        setAcademicYear(tempAcademicYear)
    }

    const formatDate = (dateStr) => {
        if (dateStr !== null && dateStr !== undefined) {
            let dateArea = dateStr.split('T')[0];
            return dateArea;
        }
    };

    const renderAcademicYearIdField = () => {
        if (!props.insertable) {
            return (
                <FormGroup>
                    <Label for="AcademicYearId">ID</Label>
                    <Input style={{ width: "20rem" }} type="text" name="AcademicYearId" 
                        value={academicYear.academicYearId} readOnly={true} id="AcademicYearId"/>
                </FormGroup>
            );
        }
        else {
            return (
                <FormGroup>
                    <Label for="AcademicYearId">ID</Label>
                    <Input style={{ width: "20rem" }} type="text" name="AcademicYearId" id="AcademicYearId"
                        value={academicYear.academicYearId} placeholder="Nhập mã năm học" 
                        onChange={e => setAcademicYearAttr("academicYearId", e.target.value.trim())}/>
                </FormGroup>
            );
        }
    }

    return (
        <div>
            <Button color={color} onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>{title}</ModalHeader>
                <ModalBody>
                    <Form onSubmit={(e) => this.updateAcademicYear(e)}>
                        {renderAcademicYearIdField()}
                        <span style={{ color: "red" }}>{errors["id"]}</span>
                        <FormGroup>
                            <Label for="AcademicYearName">Tên năm học</Label>
                            <Input style={{ width: "20rem" }} type="AcademicYearName" name="AcademicYearName"
                                value={academicYear.name} required
                                id="AcademicYearName" placeholder="Nhập tên năm học" maxLength="50"
                                onChange={e => setAcademicYearAttr("name", e.target.value.trim())} />
                            <span style={{ color: "red" }}>{errors["name"]}</span>
                        </FormGroup>
                        <FormGroup>
                            <Label for="startDate">Ngày bắt đầu năm học</Label>
                            {!insertable ?
                                <Input type="date" name="startDate" id="startDate"
                                    style={{ width: "20rem" }}
                                    value={formatDate(academicYear.startDate)}
                                    placeholder={academicYear.startDate}
                                    onChange={e => setAcademicYearAttr("startDate", e.target.value)}
                                >
                                </Input>
                                :
                                <Input type="date" name="startDate" id="startDate"
                                    style={{ width: "20rem" }}
                                    onChange={e => setAcademicYearAttr("startDate", e.target.value)}
                                >
                                </Input>
                            }
                            <span style={{ color: "red" }}>{errors["startDate"]}</span>
                        </FormGroup>
                        <FormGroup>
                            <Label for="endDate">Ngày kết thúc năm học</Label>
                            {!insertable ?
                                <Input type="date" name="endDate" id="endDate"
                                    style={{ width: "20rem" }}
                                    value={formatDate(academicYear.endDate)}
                                    placeholder={academicYear.endDate} 
                                    onChange={e => setAcademicYearAttr("endDate", e.target.value)}
                                >
                                </Input>
                                :
                                <Input type="date" name="endDate" id="endDate"
                                    style={{ width: "20rem" }}
                                    onChange={e => setAcademicYearAttr("endDate", e.target.value)}
                                >
                                </Input>
                            }
                            <span style={{ color: "red" }}>{errors["endDate"]}</span>
                        </FormGroup>
                        <FormGroup>
                            <Label for="isCurrent">Đang hoạt động?</Label>
                            <Input type="checkbox" name="isCurrent" value={academicYear.isCurrent}
                                id="isCurrent" checked={academicYear.isCurrent}
                                onChange={e => setAcademicYearAttr("isCurrent", e.target.checked)} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="description">Mô tả</Label>
                            <Input style={{ width: "20rem" }} name="description" value={academicYear.description} 
                                maxLength="50" id="description" placeholder="Nhập mô tả"
                                onChange={e => setAcademicYearAttr("description", e.target.value.trim())} />
                            <span style={{ color: "red" }}>{errors["description"]}</span>
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={updateAcademicYear}>OK</Button>
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default AcademicYearModal;